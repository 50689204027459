import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Container from "../components/Container";

const StyledFooter = styled.footer`
  padding: 2rem 0;
  text-align: center;

  nav {
    margin-bottom: 1rem;
  }

  a  {
    color: black;
    text-decoration: none;
    margin: 0 0.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <Container>
      <nav>
        <Link to="/imprint">Imprint</Link>
        <Link to="/privacy">Privacy Policy</Link>
      </nav>
      © {new Date().getFullYear()}, West Launch LLC, 305 N Peoria St., Ste 200,
      Chicago IL 60607 <br />
    </Container>
  </StyledFooter>
);

export default Footer;
