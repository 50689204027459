import "./layout.css";

import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";
import { Button } from "@material-ui/core";

import Footer from "./Footer";
import Header from "./Header";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

const MainStyled = styled.main`
  margin-top: ${(props) => (props.page !== "subpage" ? "0" : "8rem")};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: underline;
  }
`;

const Layout = ({ page, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="cookieAcceptCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            variant: "contained",
            type: "submit",
            color: "primary",
          }}
          ButtonComponent={MaterialButton}
        >
          This website uses cookies to improve the user experience.. Learn more
          about the cookies we use in ourr{" "}
          <StyledLink to="datenschutz">Privacy Policy</StyledLink>.
        </CookieConsent>
        <Header page={page} siteTitle={data.site.siteMetadata.title} />
        <MainStyled page={page}>{children}</MainStyled>
        <Footer />
      </>
    )}
  />
);

MainStyled.propTypes = {
  page: PropTypes.string,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string,
};

export default Layout;

class MaterialButton extends React.Component {
  render() {
    console.log(this);
    return (
      <Button
        color={this.props.style.color}
        variant={this.props.style.variant}
        size={this.props.style.size}
        type={this.props.style.type}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}
