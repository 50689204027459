// import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import Container from "../components/Container";
import { Link } from "gatsby";

const StyledHeader = styled.header`
  position: absolute;
  top: 0;
  padding-top: 1rem;
  width: 100%;
  z-index: 1;
`;

const StyledLogo = styled.h2`
  height: 70px;
  color: ${(props) => (props.page.page !== "subpage" ? "white" : "black")};
`;

const Header = (page, { siteTitle }) => {
  return (
    <StyledHeader>
      <Container>
        <Link to="/" style={{ textDecoration: "none" }}>
          <StyledLogo page={page}>West Launch LLC</StyledLogo>
        </Link>
      </Container>
    </StyledHeader>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  page: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  page: ``,
};

export default Header;
